import { useMemo } from 'react';

import useReverseGeocoding from '~/hooks/useReverseGeocoding';
import type { GpsSensorType } from '~/types/sensor';

export default function useAgentLocation(gpsLocation: GpsSensorType | null | undefined): {
  gps?: GpsSensorType | null;
  address?: string;
} {
  const gps = useMemo(() => gpsLocation, [gpsLocation]);

  const { address } = useReverseGeocoding(gps);

  return {
    gps,
    address,
  };
}
