import { useReactiveVar } from '@apollo/client';
import { useEffect, useCallback, useMemo } from 'react';

import {
  selectedAgentStatusesVar,
  SELECTED_AGENT_STATUSES_DEFAULT_VALUE,
} from '~/apollo/reactiveVariables/selectedAgentStatusesVar';
import useAlarmsContext from '~/context/useAlarmsContext';
import useCurrentUserContext from '~/context/useCurrentUserContext';
import { AGENT_STATUS } from '~/types/agent';
import browserStorage from '~/utils/browserStorage';

const STORAGE_KEY = 'selectedAgentStatuses';

export const AGENT_STATUSES = [
  AGENT_STATUS.alert,
  AGENT_STATUS.connectionLost,
  AGENT_STATUS.inMission,
  AGENT_STATUS.inSafeZone,
];

export default function useAgentStatuses(): {
  selectedAgentStatuses: AGENT_STATUS[];
  toggleSelectedAgentStatus: (status: AGENT_STATUS) => void;
} {
  const { currentUser } = useCurrentUserContext();
  const { hasAlert } = useAlarmsContext();
  const selectedAgentStatuses = useReactiveVar(selectedAgentStatusesVar);

  const setSelectedAgentStatuses = useCallback(
    (selectedStatuses: AGENT_STATUS[]) => {
      if (!currentUser?.id) {
        return;
      }
      browserStorage.session.set(
        STORAGE_KEY,
        {
          ...(browserStorage.session.get(STORAGE_KEY, true) || {}),
          [currentUser.id]: selectedStatuses,
        },
        true,
      );
      selectedAgentStatusesVar(selectedStatuses);
    },
    [currentUser?.id],
  );

  const toggleSelectedAgentStatus = useCallback(
    (status: AGENT_STATUS) => {
      if (selectedAgentStatuses.includes(status)) {
        setSelectedAgentStatuses(selectedAgentStatuses.filter((s) => s !== status));
      } else {
        const result = [...selectedAgentStatuses, status];
        setSelectedAgentStatuses(result.length === AGENT_STATUSES.length ? [] : result);
      }
    },
    [selectedAgentStatuses, setSelectedAgentStatuses],
  );

  useEffect(() => {
    if (!currentUser?.id) {
      selectedAgentStatusesVar(SELECTED_AGENT_STATUSES_DEFAULT_VALUE);
      return;
    }
    const cached =
      browserStorage.session.get(STORAGE_KEY, true)?.[currentUser.id] ||
      SELECTED_AGENT_STATUSES_DEFAULT_VALUE;
    // Can happen only on app load
    if (!selectedAgentStatuses.length && cached.length) {
      selectedAgentStatusesVar(cached);
    }
  }, [currentUser?.id, selectedAgentStatuses.length]);

  useEffect(() => {
    if (hasAlert && currentUser?.id) {
      // delayed to make sure on app load it comes after useAgentStatuses init
      setTimeout(() => setSelectedAgentStatuses(SELECTED_AGENT_STATUSES_DEFAULT_VALUE), 0);
    }
  }, [hasAlert, setSelectedAgentStatuses, currentUser?.id]);

  return useMemo(
    () => ({ selectedAgentStatuses, toggleSelectedAgentStatus }),
    [selectedAgentStatuses, toggleSelectedAgentStatus],
  );
}
