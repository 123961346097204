import InfoCircleOutlined from '@ant-design/icons/InfoCircleOutlined';
import { Button, Modal, Tooltip } from 'antd';
import { memo, useMemo, useState } from 'react';
import styled from 'styled-components';

import AlarmSteps from '~/components/AlarmSteps';
import GenericItemList, { DEFAULT_VALUE, type GenericItem } from '~/components/GenericItemList';
import TimeAgo from '~/components/TimeAgo';
import useAlarmsContext from '~/context/useAlarmsContext';
import i18n from '~/locales/i18n';
import theme from '~/theme';
import type { ModalProps } from '~/types/modal';
import isAlarmOngoing from '~/utils/alarm/isAlarmOngoing';
import { formatDateTime } from '~/utils/dateTime';
import getYesOrNo from '~/utils/parse/getYesOrNo';

const GridDiv = styled.div`
  margin: 16px 0;
  display: grid;
  grid-template-columns: minmax(0, 1fr) minmax(0, auto);
  gap: 16px;

  ${theme.medias.lteSmall} {
    grid-template-columns: minmax(0, 1fr);
    margin-bottom: 8px;
  }
`;

const ViewMoreSpan = styled.span`
  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
`;

const RightDiv = styled.div`
  display: flex;
  flex-direction: column;
`;

const TooltipUl = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`;

const BottomDiv = styled.div`
  font-size: 12px;
  opacity: 0.5;
`;

export interface AlarmInformationModalProps {
  alarmId: string | undefined;
  openAlarmControlModal: (params: {
    agentId: string | undefined;
    alarmId: string | undefined;
  }) => void;
}

const AlarmInformationModal = memo(
  ({
    isOpen,
    onClose,
    alarmId,
    openAlarmControlModal,
  }: ModalProps & AlarmInformationModalProps) => {
    const [isDismissReasonDetailsOpen, setIsDismissReasonDetailsOpen] = useState<boolean>(false);

    const { alarms } = useAlarmsContext();

    const alarm = useMemo(() => alarms?.find((a) => a.id === alarmId), [alarms, alarmId]);

    const agentNameWithAcronym = alarm?.carrier?.nameWithAcronym;

    const dismissReasonDetailsValue = useMemo(
      () =>
        isDismissReasonDetailsOpen ? (
          alarm?.dismiss_reason_details
        ) : (
          <ViewMoreSpan
            onClick={() => {
              setIsDismissReasonDetailsOpen(true);
            }}
          >
            {i18n.t('alarmsPage.card.viewMore')}
          </ViewMoreSpan>
        ),
      [alarm?.dismiss_reason_details, isDismissReasonDetailsOpen],
    );

    const descriptions: GenericItem[] = useMemo(
      () => [
        {
          label: i18n.t('alarmsPage.alarmFields.created_at'),
          value: alarm?.created_at ? formatDateTime(alarm.created_at) : DEFAULT_VALUE,
        },
        {
          label: i18n.t('alarmsPage.alarmFields.agent'),
          value: agentNameWithAcronym || DEFAULT_VALUE,
        },
        {
          label: i18n.t('alarmsPage.alarmFields.level'),
          value: alarm?.level
            ? i18n.t(`general.alarm.level.${alarm.level.toLowerCase()}`)
            : DEFAULT_VALUE,
        },
        {
          label: i18n.t('alarmsPage.alarmFields.source'),
          value: alarm?.source
            ? i18n.t(`general.alarm.source.${alarm.source.toLowerCase()}`)
            : DEFAULT_VALUE,
        },
        {
          label: i18n.t('alarmsPage.alarmFields.status'),
          value: alarm?.status
            ? i18n.t(`general.alarm.status.${alarm.status.toLowerCase()}`)
            : DEFAULT_VALUE,
        },
        {
          label: i18n.t('alarmsPage.alarmFields.type'),
          value: alarm?.type ? i18n.t(`general.alarm.type.${alarm.type}`) : DEFAULT_VALUE,
        },
        {
          label: i18n.t('alarmsPage.alarmFields.value'),
          value: alarm?.value && alarm?.value !== 'undefined' ? alarm.value : DEFAULT_VALUE,
        },
        {
          label: i18n.t('alarmsPage.alarmFields.assigned_at'),
          value: alarm?.assigned_at ? formatDateTime(alarm.assigned_at) : DEFAULT_VALUE,
        },
        {
          label: i18n.t('alarmsPage.alarmFields.administrator'),
          value: alarm?.administrator?.email || alarm?.administrator?.id || DEFAULT_VALUE,
        },
        {
          label: i18n.t('alarmsPage.alarmFields.dismiss_requested_at'),
          value: alarm?.dismiss_requested_at
            ? formatDateTime(alarm.dismiss_requested_at)
            : DEFAULT_VALUE,
        },
        {
          label: i18n.t('alarmsPage.alarmFields.dismissed_at'),
          value: alarm?.dismissed_at ? formatDateTime(alarm.dismissed_at) : DEFAULT_VALUE,
        },
        {
          label: i18n.t('alarmsPage.alarmFields.dismissed_by'),
          value: alarm?.dismissed_by?.email || alarm?.dismissed_by?.id || DEFAULT_VALUE,
        },
        {
          label: i18n.t('alarmsPage.alarmFields.dismiss_reason'),
          value: alarm?.dismiss_reason || DEFAULT_VALUE,
        },
        {
          label: i18n.t('alarmsPage.alarmFields.dismiss_reason_details'),
          value:
            alarm?.dismiss_reason_details && alarm?.dismiss_reason_details !== '{}'
              ? dismissReasonDetailsValue
              : DEFAULT_VALUE,
        },
      ],
      [alarm, agentNameWithAcronym, dismissReasonDetailsValue],
    );

    const isOngoing = isAlarmOngoing(alarm);

    return (
      <Modal
        title={
          <>
            {i18n.t('alarmsPage.alarmDetails')}
            {isOngoing ? (
              ` (${i18n.t('alarmsPage.steps.ongoing').toLowerCase()})`
            ) : (
              <>
                {' '}
                (<TimeAgo date={alarm?.created_at || new Date()} />)
              </>
            )}
          </>
        }
        footer={null}
        centered
        width={800}
        open={isOpen}
        onCancel={onClose}
      >
        <GridDiv>
          <GenericItemList items={descriptions} />
          <RightDiv>
            {isOngoing && (
              <Button
                style={{ marginBottom: '16px' }}
                type="primary"
                danger
                onClick={(event) => {
                  event.stopPropagation();
                  openAlarmControlModal({
                    agentId: alarm?.carrier?.id,
                    alarmId: alarm?.id,
                  });
                }}
              >
                {i18n.t('carrierDetailsPopup.alarmControlModal.title')}
              </Button>
            )}
            <AlarmSteps alarm={alarm} />
          </RightDiv>
        </GridDiv>
        <BottomDiv>
          <Tooltip
            title={
              <TooltipUl>
                <li>
                  <b>{i18n.t('alarmsPage.alarmFields.assignable')}:</b>{' '}
                  {getYesOrNo(!!alarm?.can_assign)}
                </li>
                <li>
                  <b>{i18n.t('alarmsPage.alarmFields.cancellable')}:</b>{' '}
                  {getYesOrNo(!!alarm?.can_cancel)}
                </li>
                <li>
                  <b>{i18n.t('alarmsPage.alarmFields.dismissable')}:</b>{' '}
                  {getYesOrNo(!!alarm?.can_dismiss_dashboard)}
                </li>
              </TooltipUl>
            }
          >
            <span style={{ cursor: 'help' }}>
              <InfoCircleOutlined />
            </span>
          </Tooltip>{' '}
          <b>{i18n.t('alarmsPage.alarmFields.alarmId')}:</b> {alarm?.id}
        </BottomDiv>
      </Modal>
    );
  },
);

AlarmInformationModal.displayName = 'AlarmInformationModal';

export default AlarmInformationModal;
