import ClockCircleOutlined from '@ant-design/icons/ClockCircleOutlined';
import CloudDownloadOutlined from '@ant-design/icons/CloudDownloadOutlined';
import { Button, ConfigProvider, DatePicker, Modal, Tooltip } from 'antd';
import dayjs from 'dayjs';
import groupBy from 'lodash/groupBy';
import orderBy from 'lodash/orderBy';
import { useState, useEffect, useMemo, useRef, memo } from 'react';
import styled from 'styled-components';

import useQueryCarrierLocationHistory from '~/apollo/hooks/locationHistory/useQueryCarrierLocationHistory';
import i18n, { currentLanguage } from '~/locales/i18n';
import theme from '~/theme';
import type { ModalProps } from '~/types/modal';
import { formatTime, formatDate } from '~/utils/dateTime';
import logger from '~/utils/logger';

import AgentHeader from './components/AgentHeader';
import AgentSelect from './components/AgentSelect';
import LocationHistoryMap from './components/LocationHistoryMap';
import getHourFromTimestamp from './utils/getHourFromTimestamp';

const BORDER = '1px solid #e8e8e8';
const MODAL_CONTENT_HEIGHT = '600px';

const SIDEBAR_WIDTH = 280;

const StyledModal = styled(Modal)`
  max-width: 100%;

  & > div.ant-modal-content {
    padding: 0;

    & > div.ant-modal-header {
      padding: 16px 16px;
      margin: 0;
      border-bottom: ${BORDER};
    }
  }
`;

const GridDiv = styled.div`
  display: grid;
  grid-template-columns: minmax(0, 1fr) minmax(0, ${SIDEBAR_WIDTH}px);
  height: ${MODAL_CONTENT_HEIGHT};
`;

const LeftDiv = styled.div`
  height: 100%;
  max-height: 100%;
  border-right: ${BORDER};
  height: ${MODAL_CONTENT_HEIGHT};
  background-color: ${theme.colors.lightGrey};
  background: linear-gradient(
    110deg,
    ${theme.colors.lightGrey} 8%,
    #f5f5f5 18%,
    ${theme.colors.lightGrey} 33%
  );
  background-size: 400% 100%;
  animation: 10s ${theme.keyframes.shine} linear infinite;

  img {
    max-width: 100%;
  }

  p {
    font-size: 16px;
  }
`;

const RightDiv = styled.div`
  height: ${MODAL_CONTENT_HEIGHT};
  overflow: hidden;
  display: inline-grid;
  grid-template-rows: minmax(0, auto) minmax(0, 1fr);
  align-items: baseline;
`;

const RightTopDiv = styled.div`
  border-bottom: ${BORDER};
  padding: 12px;
  background: ${theme.colors.primaryBlue};
`;

const RightMiddleDiv = styled.div`
  border-bottom: ${BORDER};
  padding: 12px;
`;

const RightListDiv = styled.div`
  overflow-y: auto;
  height: 100%;
`;

const ItemButton = styled.button<{ $active: boolean }>`
  cursor: pointer;
  background: ${(props) => (props.$active ? theme.colors.blue : 'transparent')};
  color: ${(props) => (props.$active ? theme.colors.white : theme.colors.black)};
  padding: 12px;
  border: none;
  border-bottom: ${BORDER};
  font-size: 16px;
  text-align: left;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &:hover {
    background: ${(props) => (props.$active ? theme.colors.blue : theme.colors.lightGrey)};
  }
`;

const TimeDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  margin-bottom: 12px;
`;

const DateDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 13px;
  opacity: 0.75;
`;

const DATE_FORMAT = 'YYYY-MM-DD';

export interface LocationHistoryModalProps {
  agentId: string;
}

const LocationHistoryModal = memo(
  ({ isOpen, onClose, agentId }: ModalProps & LocationHistoryModalProps) => {
    const [activeHourHover, setActiveHourHover] = useState<string | null>(null); // Example: 06, 12, 18, etc...
    const [activeHourClick, setActiveHourClick] = useState<string | null>(null);

    const pointsListRef = useRef<HTMLDivElement>(null);

    const [activeAgentId, setActiveAgentId] = useState<string>(agentId);

    const { locationPoints, isLoading, error, hasNextPage, fetchNextPage, isNextPageLoading } =
      useQueryCarrierLocationHistory({
        carrierId: agentId,
      });

    const locationPointsByDay = useMemo(
      () => groupBy(locationPoints, (point) => point.timestamp.split('T')[0]),
      [locationPoints],
    );

    const availableDays: string[] = useMemo(
      () => Object.keys(locationPointsByDay) || [],
      [locationPointsByDay],
    );

    const latestDay = useMemo(() => availableDays.at(-1), [availableDays]);

    const [activeDay, setActiveDay] = useState<string | undefined>(latestDay);

    useEffect(() => {
      setActiveDay(latestDay);
    }, [latestDay]);

    useEffect(() => {
      pointsListRef.current?.scrollTo(0, 0);
      if (isOpen) {
        logger.log('LocationHistoryModal: opened modal');
      }
    }, [isOpen]);

    const activeLocationPoints = useMemo(
      () =>
        activeDay
          ? locationPoints.filter((point) => point.timestamp.startsWith(activeDay))
          : locationPoints,
      [activeDay, locationPoints],
    );

    const groupedSidebarLocationPoints = useMemo(
      () => groupBy(activeLocationPoints, (point) => getHourFromTimestamp(point.timestamp)),
      [activeLocationPoints],
    );

    const datePickerPresets = useMemo(
      () =>
        [
          ...Object.entries(locationPointsByDay).map(([day, points]) => ({
            label: (
              <div
                style={{ color: activeDay === day ? theme.colors.primaryBlue : theme.colors.black }}
              >
                <b>
                  {new Date(day).toLocaleString(currentLanguage(), {
                    weekday: 'short',
                    year: 'numeric',
                    month: 'short',
                    day: 'numeric',
                    hour: undefined,
                    minute: undefined,
                    second: undefined,
                  })}
                </b>{' '}
                ({points.length})
              </div>
            ),
            value: dayjs(day, DATE_FORMAT),
            dayTimestamp: day,
          })),
        ].reverse(),
      [activeDay, locationPointsByDay],
    );

    const datePickerValue = useMemo(
      () => (activeDay ? dayjs(activeDay, DATE_FORMAT) : undefined),
      [activeDay],
    );

    const latestPoint = activeLocationPoints?.at(0);

    const nextPageButtonTooltip = hasNextPage
      ? i18n.t('locationHistory.fetchMoreTooltipStart', {
          currentLoadedPoints: locationPoints.length,
        })
      : i18n.t('locationHistory.fetchMoreTooltipEnd', {
          currentLoadedPoints: locationPoints.length,
        });

    const hasNextPageFromActiveDay =
      hasNextPage &&
      activeDay &&
      !datePickerPresets.some((value) => value.dayTimestamp < activeDay);

    return (
      <StyledModal
        title={i18n.t('locationHistory.title')}
        footer={null}
        centered
        width={1200}
        open={isOpen}
        onCancel={onClose}
      >
        <GridDiv>
          <LeftDiv>
            <LocationHistoryMap
              key={`${activeDay}-${activeLocationPoints.length}`}
              agentId={agentId}
              activeHourHover={activeHourHover}
              activeHourClick={activeHourClick}
              locationPoints={activeLocationPoints}
              isLoading={isLoading}
              error={error}
            />
          </LeftDiv>
          <RightDiv>
            <div>
              <RightTopDiv>
                <AgentHeader activeAgentId={activeAgentId} />
                <AgentSelect activeAgentId={activeAgentId} setActiveAgentId={setActiveAgentId} />
                <ConfigProvider
                  theme={{
                    components: {
                      DatePicker: {
                        presetsMaxWidth: 260,
                      },
                    },
                  }}
                >
                  <DatePicker
                    placement="bottomRight"
                    style={{ width: '100%', marginTop: '12px' }}
                    key={activeAgentId}
                    allowClear={false}
                    format={DATE_FORMAT}
                    value={datePickerValue}
                    presets={datePickerPresets}
                    onChange={(date, dateString) => {
                      setActiveDay(dateString);
                    }}
                    disabledDate={(current) => {
                      const currentDay = current.format(DATE_FORMAT);
                      return !availableDays.includes(currentDay);
                    }}
                  />
                </ConfigProvider>
              </RightTopDiv>
              <RightMiddleDiv>
                {isLoading && <div>{i18n.t('general.common.loading')}</div>}
                {!isLoading && locationPoints.length === 0 && (
                  <div>{i18n.t('locationHistory.noData')}.</div>
                )}
                {!isLoading && activeDay && latestPoint && (
                  <div>
                    <b>
                      {new Date(latestPoint.timestamp).toLocaleString(currentLanguage(), {
                        weekday: 'long',
                        year: 'numeric',
                        month: 'long',
                        day: 'numeric',
                        hour: undefined,
                        minute: undefined,
                        second: undefined,
                      })}
                    </b>
                  </div>
                )}
                {!isLoading && locationPoints.length > 0 && (
                  <>
                    <div>
                      <b>{i18n.t('locationHistory.pointsTotal')}:</b>{' '}
                      {`${locationPoints.length}${hasNextPage ? '+' : ''}`}
                    </div>
                    <div>
                      <b>{i18n.t('locationHistory.pointsDay')}:</b>{' '}
                      {`${activeLocationPoints.length}${hasNextPageFromActiveDay ? '+' : ''}`}
                    </div>
                    <Tooltip
                      title={
                        isNextPageLoading ? i18n.t('general.common.loading') : nextPageButtonTooltip
                      }
                      placement="bottomLeft"
                    >
                      <Button
                        type="primary"
                        style={{ marginTop: '5px' }}
                        loading={isNextPageLoading}
                        disabled={!hasNextPage}
                        size="small"
                        onClick={() => {
                          fetchNextPage();
                        }}
                        icon={<CloudDownloadOutlined />}
                      >
                        {i18n.t('locationHistory.fetchMore')}
                      </Button>
                    </Tooltip>
                  </>
                )}
              </RightMiddleDiv>
            </div>
            {!isLoading && locationPoints.length > 0 && (
              <RightListDiv ref={pointsListRef} data-id="locationHistoryList">
                {orderBy(Object.entries(groupedSidebarLocationPoints), ['0'], ['asc']).map(
                  ([hour, hourPoints], index) => {
                    const pointsWithinHour = orderBy(hourPoints, ['timestamp'], ['desc']);

                    const newestPoint = pointsWithinHour.at(0);
                    const oldestPoint = pointsWithinHour.at(-1);

                    return (
                      <div key={hour}>
                        <ItemButton
                          $active={false}
                          onClick={() => {
                            setActiveHourClick(hour);
                            setActiveHourHover(hour);
                          }}
                          onPointerEnter={() => {
                            setActiveHourHover(hour);
                          }}
                          onPointerLeave={() => {
                            setActiveHourHover(null);
                          }}
                        >
                          <div style={{ width: '100%' }}>
                            {oldestPoint?.timestamp && newestPoint?.timestamp && (
                              <TimeDiv>
                                <span>
                                  <b>
                                    <ClockCircleOutlined /> {hour}h
                                  </b>
                                </span>
                                <span
                                  // eslint-disable-next-line react/no-danger
                                  dangerouslySetInnerHTML={{
                                    __html: i18n.t<string>('locationHistory.fromToHtml', {
                                      from: formatTime(oldestPoint.timestamp, 'standard'),
                                      to: formatTime(newestPoint.timestamp, 'standard'),
                                    }),
                                  }}
                                />
                              </TimeDiv>
                            )}
                            {oldestPoint?.timestamp && (
                              <DateDiv>
                                <span>{formatDate(oldestPoint.timestamp, 'readable')}</span>
                                <span>
                                  {pointsWithinHour.length || 0}
                                  {index === 0 && hasNextPageFromActiveDay ? '+' : ''}{' '}
                                  {pointsWithinHour.length === 1 ? 'point' : 'points'}
                                </span>
                              </DateDiv>
                            )}
                          </div>
                        </ItemButton>
                      </div>
                    );
                  },
                )}
              </RightListDiv>
            )}
          </RightDiv>
        </GridDiv>
      </StyledModal>
    );
  },
);

LocationHistoryModal.displayName = 'LocationHistoryModal';

export default LocationHistoryModal;
