import { memo } from 'react';
import styled from 'styled-components';

import type { Agent } from '~/types/agent';
import type { Vehicle } from '~/types/vehicle';

import InfoLocation from './components/InfoLocation';
import InfoMissionDuration from './components/InfoMissionDuration';
import InfoPhoneNumber from './components/InfoPhoneNumber';
import InfoSpeed from './components/InfoSpeed';
import PhoneBatteryAndLteSignal from './components/PhoneBatteryAndLteSignal';
import useAgentLocation from './hooks/useAgentLocation';

const GridDiv = styled.div`
  display: grid;
  gap: 8px;
  grid-template-columns: minmax(0, 1fr) minmax(0, auto);
  align-items: start;
`;

const InnerDiv = styled.div`
  display: grid;
  gap: 8px;
  grid-template-rows: auto;
`;

interface Props {
  agent: Agent | undefined;
  vehicle: Vehicle | undefined;
}

const SectionInfo = memo(({ agent, vehicle }: Props) => {
  const { address, gps } = useAgentLocation(vehicle?.location || agent?.sensors?.gps);

  return (
    <GridDiv data-id="SectionInfo">
      <InnerDiv>
        <InfoLocation gps={gps} address={address} />
        <InfoSpeed gps={gps} />
        <InfoMissionDuration agent={agent} />
        <InfoPhoneNumber agent={agent} />
      </InnerDiv>
      <PhoneBatteryAndLteSignal agent={agent} />
    </GridDiv>
  );
});

SectionInfo.displayName = 'SectionInfo';

export default SectionInfo;
