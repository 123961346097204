const variables = {
  environment: process.env.REACT_APP_ENVIRONMENT || 'local',
  version: process.env.REACT_APP_VERSION,
  commitHash: process.env.REACT_APP_COMMIT_HASH,
  commitDate: process.env.REACT_APP_COMMIT_DATE,
  region: process.env.REACT_APP_REGION || 'eu-west-1',
  mainApiUrl:
    'https://api.video-lab.dev.wearin.cloud/graphql' ||
    process.env.REACT_APP_MAIN_API_URL ||
    'https://7zarskstknffpmwrzl3mp4juqe.appsync-api.eu-west-1.amazonaws.com/graphql',
  datalakeApiUrl:
    process.env.REACT_APP_DATALAKE_API_URL ||
    'https://owoycpebkvdvve7ansrgqwipsm.appsync-api.eu-west-1.amazonaws.com/graphql',
  adminUserPoolId:
    'eu-west-1_vPPJchCav' || process.env.REACT_APP_ADMIN_USER_POOL_ID || 'eu-west-1_guer5nJL7',
  carrierUserPoolId: 'eu-west-1_y6NOCEqUJ' || process.env.REACT_APP_CARRIER_USER_POOL_ID || null,
  dashboardClientId:
    '3jh2lhmgebb3n8lmocv6v7fi5g' ||
    process.env.REACT_APP_DASHBOARD_CLIENT_ID ||
    '7j11srlq0fbnc5nlsnsciaui1k',
  mobileClientId: '4uqbmphdkge8kreib4gsr1oep3' || process.env.REACT_APP_MOBILE_CLIENT_ID || null,
  googleMapsApiKey: 'AIzaSyDB_0Xk0rbaTldJi3pAiuRI_NPSwnZo1Ac',
  basename: process.env.REACT_APP_BASENAME ?? '/',
  awsConfig: {
    aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
    aws_appsync_apiKey: 'null',
  },
} as const;

window.wearin_dashboard_version = {
  version: variables.version,
  commitHash: variables.commitHash,
  commitDate: variables.commitDate,
};

export default variables;
