import { Wrapper } from '@googlemaps/react-wrapper';
import { memo, type ReactNode } from 'react';

import variables from '~/config/variables';

import MapContent, { type Props } from './MapContent';

const Map = memo(
  ({
    children,
    isLoading,
    initialZoom,
    initialLat,
    initialLng,
    followingZoom,
    followingLat,
    followingLng,
  }: { children?: ReactNode } & Props) => (
    <Wrapper apiKey={variables.googleMapsApiKey}>
      <MapContent
        isLoading={isLoading}
        initialZoom={initialZoom}
        initialLat={initialLat}
        initialLng={initialLng}
        followingZoom={followingZoom}
        followingLat={followingLat}
        followingLng={followingLng}
      />
      {children}
    </Wrapper>
  ),
);

Map.displayName = 'Map';

export default Map;
