import { useQuery, type ApolloError } from '@apollo/client';
import { useEffect, useMemo, useState } from 'react';
import { useCookies, type ReactCookieProps } from 'react-cookie';

import QUERY_VIDEO_RECORDING, {
  type VideoRecordingQueryData,
  type VideoRecordingQueryVariables,
} from '~/apollo/operations/queries/QueryVideoRecording';
import type { VideoRecording } from '~/types/videoRecording';

export default function useQueryVideoRecording({
  videoRecordingId,
}: {
  videoRecordingId: string | undefined;
}): {
  videoRecording?: VideoRecordingQueryData['video_recording'];
  isLoading: boolean;
  error?: ApolloError;
} {
  const [videoRecording, setVideoRecording] = useState<VideoRecording | undefined>();

  const { data, loading, error } = useQuery<VideoRecordingQueryData, VideoRecordingQueryVariables>(
    QUERY_VIDEO_RECORDING,
    {
      variables: {
        videoRecordingId: videoRecordingId || '',
      },
      skip: !videoRecordingId,
    },
  );

  const [, setCookie, removeCookie] = useCookies();

  useEffect(() => {
    if (data?.video_recording?.view_cookies) {
      data.video_recording.view_cookies.forEach((cookie) => {
        const url = data.video_recording?.view_url || '';
        const options: ReactCookieProps['defaultSetOptions'] = {
          path: url.substring(0, url.lastIndexOf('/')), // Remove .m3u8 filename
        };
        removeCookie(cookie.name, options);
        setCookie(cookie.name, cookie.value, options);
      });
      setVideoRecording(data.video_recording);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(data?.video_recording?.view_cookies)]);

  return useMemo(
    () => ({
      videoRecording,
      isLoading: loading,
      error,
    }),
    [videoRecording, loading, error],
  );
}
