export enum ALARM_LEVEL {
  Critical = 'Critical',
  Warning = 'Warning',
  Information = 'Information',
}

export enum ALARM_SOURCE {
  Device = 'Device',
  External = 'External',
}

export enum ALARM_TYPE {
  fall = 'fall',
  emergency = 'emergency',
  attack = 'attack',
  traak_front = 'traak_front',
  traak_back = 'traak_back',
  gasCH4HC = 'gasCH4HC',
  gasCO = 'gasCO',
  gasCO2 = 'gasCO2',
  gasH2S = 'gasH2S',
  gasO2 = 'gasO2',
}

export enum ALARM_STATUS {
  Active = 'Active',
  Assigned = 'Assigned',
  Dismissed = 'Dismissed',
}

export enum ALARM_DISMISS_TYPE {
  Acknowledged = 'Acknowledged',
  Cancelled = 'Cancelled',
}

export interface Alarm {
  __typename?: 'Alarm';
  id: string;
  level: ALARM_LEVEL;
  source: ALARM_SOURCE;
  type: ALARM_TYPE;
  value: string | number;
  created_at: string;
  status: ALARM_STATUS;
  can_cancel: boolean;
  can_assign: boolean;
  assigned_at: string | null;
  administrator: {
    id: string;
    email: string;
    first_name: string | null;
    last_name: string | null;
  } | null;
  can_dismiss_dashboard: boolean;
  dismissed_at: string | null;
  dismiss_requested_at: string | null;
  dismiss_reason: ALARM_DISMISS_TYPE | string | null;
  dismiss_reason_details: string | null; // JSON
  dismissed_by: {
    id: string;
    email: string;
  } | null;
}

export interface AlarmWithCarrier extends Alarm {
  carrier: {
    id: string;
    nameWithAcronym: string;
  };
}
