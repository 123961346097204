import { memo, type CSSProperties } from 'react';
import styled from 'styled-components';

import theme from '~/theme';
import type { Alarm } from '~/types/alarm';
import computeAlarmMessage from '~/utils/alarm/computeAlarmMessage';

const Div = styled.div`
  background: #ffe1e1;
  color: ${theme.colors.red};
  padding: 4px 8px;
  border-radius: 8px;
  display: inline-block;
`;

interface Props {
  alarm: Alarm;
  className?: string;
  style?: CSSProperties;
}

const AlarmTypePill = memo(({ alarm, className, style }: Props) => (
  <Div className={className} style={style}>
    {computeAlarmMessage(alarm)}
  </Div>
));

AlarmTypePill.displayName = 'AlarmTypePill';

export default AlarmTypePill;
